import React, { Component } from 'react'
import { graphql } from 'gatsby'
import styled, { css, keyframes } from 'styled-components'
import Img from 'gatsby-image'
import { Parallax } from 'react-scroll-parallax'
import {
  Row,
  Col,
  Gap,
  Text,
  Section,
  Title,
  Button,
  VH,
  HpTop,
  Fill,
  Video,
  RH,
  ImageOverlay,
  SEO,
} from '../components'
import { up, addResponsivity } from '../lib/styles'

import contactVideo from '../data/videos/contact.mp4'

const showVideoBp = 1200

const ContactSection = styled(Section.Simple)`
  width: 100%;
  position: relative;
  ${up(showVideoBp)} {
    position: absolute;
  }
`

const movePlanes = keyframes`
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-50%);
  }
`

// const planesWidth = 3639 * 2
const planesWidth = 3545 * 2
const planesHeight = 900
const planesWidthMobile = planesWidth / 2
const planesWidthTablet = planesWidth / 1.5

const Planes = styled.div`
  display: flex;
  flex-direction: row;

  transform: translateX(-50%);
  animation: 60s ${movePlanes} linear 1s infinite;

  width: ${planesWidthMobile}px;
  height: ${planesHeight / 2}px;

  ${up('mobile')} {
    width: ${planesWidthTablet}px;
    height: ${planesHeight / 1.5}px;
  }
  ${up('tablet')} {
    width: ${planesWidth}px;
    height: ${planesHeight}px;
  }
`

const PlanesImg = styled(Img)`
  width: 100%;
  height: 100%;
`

const PlanesImageWrapper = styled.div`
  width: 50%;
`

const Box = ({ ...props }) => (
  <Col background="black" textAlign="center" width="503px" maxWidth="100%" {...props} />
)

const MobileBgWrapper = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  height: 300px;
`

class Contact extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showVideo: false,
    }
  }

  componentDidMount() {
    this.setShowVideo()
    window.addEventListener('resize', this.setShowVideo)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setShowVideo)
  }

  setShowVideo = () => {
    const windowWidth = window && window.innerWidth
    const showVideo = windowWidth > showVideoBp
    this.setState({ showVideo })
  }

  render() {
    const {
      location: { pathname },
      data: {
        contactImage,
        contactMobileBgImage,
        planesImage,
        planesImageMedium,
        planesImageSmall,
      } = {},
    } = this.props
    const { showVideo } = this.state

    const planesImageSources = [
      {
        ...planesImageMedium.image.fixed,
        media: '(min-width: 640px) and (max-width: 979px)',
      },
      {
        ...planesImage.image.fixed,
        media: '(min-width: 980px)',
      },
      {
        ...planesImageSmall.image.fixed,
        media: '(max-width: 639px)',
      },
    ]

    return (
      <>
        <SEO
          title="Contact | Zuri"
          description="We are developing a modern private aircraft with vertical takeoff and landing. This will allow you to affordably travel from city to city at distances of up to 700 km apart."
          pathname={pathname}
        />

        <Gap.Top />

        <Fill height="80px" showAfter="tablet" />
        <Col position="relative">
          {showVideo && (
            <Row position="relative" showAfter={showVideoBp}>
              <Video src={contactVideo} autoPlay muted loop />
            </Row>
          )}
          <ContactSection transparent>
            <Row
              justifyContent="space-around"
              alignItems="flex-start"
              mobileAlignItems="center"
              responsive
              bp="tablet"
            >
              <Box>
                <Col withPagePadding>
                  <Gap gap="38px" />
                  <Title.Large>CONTACT US</Title.Large>
                  <Gap gap="60px" />

                  <Text.Contact>
                    MICHAL ILLICH
                    <br />
                    FOUNDER
                    <br />
                    <a href="mailto:michal@zuri.com">michal@zuri.com</a>
                  </Text.Contact>
                  <Gap gap="80px" />

                  <Text.Contact>
                    PRESS
                    <br />
                    <a href="mailto:press@zuri.com">press@zuri.com</a>
                  </Text.Contact>
                  <Gap gap="60px" />

                  <Text.Contact>
                    CAREER
                    <br />
                    <a href="mailto:jobs@zuri.com">jobs@zuri.com</a>
                  </Text.Contact>
                  <Gap gap="60px" />

                  <Text.Contact>
                    INVESTOR RELATIONS
                    <br />
                    <a href="mailto:investor@zuri.com">investor@zuri.com</a>
                  </Text.Contact>
                  <Gap gap="97px" />
                </Col>
              </Box>
              {!showVideo && (
                <Col showBelow="tablet" width="100%">
                  <MobileBgWrapper>
                    <Parallax y={[-50, 50]}>
                      <Img fluid={contactMobileBgImage.image.fluid} />
                    </Parallax>
                  </MobileBgWrapper>
                </Col>
              )}
              <Box>
                <Col withPagePadding>
                  <Gap gap="38px" />
                  <Title.Large>VISIT US</Title.Large>
                  <Gap gap="60px" />
                  <Text.Contact>OFFICE:</Text.Contact>
                  <Gap gap="18px" />
                  <a
                    href="https://maps.app.goo.gl/c3eKV7gmupGYBaFm7"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Text.Contact>
                      U Habrovky 247/11
                      <br />
                      Prague 4, 140 00
                    </Text.Contact>
                  </a>
                  <Gap gap="48px" />
                  <Text.Contact>HALL:</Text.Contact>
                  <Gap gap="18px" />
                  <a
                    href="https://maps.app.goo.gl/5LYPwtBERtw1pZJs7"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Text.Contact>
                      Naskove 3
                      <br />
                      Prague
                    </Text.Contact>
                  </a>
                  <Gap gap="48px" />

                  <Text.Contact>Zuri.com SE</Text.Contact>
                  <Text.Contact>VAT ID: CZ06403727</Text.Contact>
                </Col>
                <Gap gap="48px" />
                <Col position="relative">
                  <ImageOverlay />
                  <Img fluid={contactImage.image.fluid} />
                </Col>
                <Gap gap="60px" />
              </Box>
            </Row>
          </ContactSection>
        </Col>

        <Section background="black">
          <Col alignItems="center">
            <Gap gap="170px" mobileGap="0" bp="tablet" />

            <Title.Large textAlign="center">PERFECT LOCATION</Title.Large>
            <Gap gap="35px" />
            <Text.Contact
              textAlign="center"
              maxWidth="915px"
              lineHeight="33px"
              mobileFontSize="20px"
              mobileLineHeight="28px"
            >
              Czech Republic has 100+ years of aerospace tradition - the first czech airplane flew
              in 1911. Today we have dozens of airplane manufacturers, mainly in general aviation
              and ultralight segment and great universities teaching airplane engineering, design,
              aerodynamics and other specializations.
            </Text.Contact>

            <Gap gap="180px" mobileGap="58px" bp="tablet" />
          </Col>
        </Section>

        <Col width="100%" position="relative">
          <Planes>
            <PlanesImageWrapper>
              <PlanesImg style={{ height: '100%', width: '100%' }} fixed={planesImageSources} />
            </PlanesImageWrapper>
            <PlanesImageWrapper>
              <PlanesImg style={{ height: '100%', width: '100%' }} fixed={planesImageSources} />
            </PlanesImageWrapper>
          </Planes>
        </Col>
      </>
    )
  }
}

export default Contact

export const pageQuery = graphql`
  fragment ContactImage on File {
    image: childImageSharp {
      fluid(maxWidth: 504, quality: 100) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }

  fragment ContactmobileBgImage on File {
    image: childImageSharp {
      fluid(maxWidth: 981, quality: 100) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }

  fragment PlanesImage on File {
    image: childImageSharp {
      fixed(width: 3545, height: 900, quality: 90) {
        ...GatsbyImageSharpFixed_withWebp
      }
    }
  }
  fragment PlanesImageMedium on File {
    image: childImageSharp {
      fixed(width: 2363, height: 600, quality: 80) {
        ...GatsbyImageSharpFixed_withWebp
      }
    }
  }
  fragment PlanesImageSmall on File {
    image: childImageSharp {
      fixed(width: 1773, height: 450, quality: 80) {
        ...GatsbyImageSharpFixed_withWebp
      }
    }
  }

  query {
    contactImage: file(relativePath: { eq: "images/contact/zurihouse2.jpg" }) {
      ...ContactImage
    }

    contactMobileBgImage: file(relativePath: { eq: "images/contact/bg-mobile.jpg" }) {
      ...ContactImage
    }

    planesImage: file(relativePath: { eq: "images/contact/planes.jpg" }) {
      ...PlanesImage
    }
    planesImageMedium: file(relativePath: { eq: "images/contact/planes.jpg" }) {
      ...PlanesImageMedium
    }
    planesImageSmall: file(relativePath: { eq: "images/contact/planes.jpg" }) {
      ...PlanesImageSmall
    }
  }
`
